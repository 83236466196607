exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-crypto-risk-management-mdx": () => import("./../../../src/pages/articles/crypto-risk-management.mdx" /* webpackChunkName: "component---src-pages-articles-crypto-risk-management-mdx" */),
  "component---src-pages-articles-how-to-calculate-yield-farming-returns-mdx": () => import("./../../../src/pages/articles/how-to-calculate-yield-farming-returns.mdx" /* webpackChunkName: "component---src-pages-articles-how-to-calculate-yield-farming-returns-mdx" */),
  "component---src-pages-articles-how-to-use-the-calculator-mdx": () => import("./../../../src/pages/articles/how-to-use-the-calculator.mdx" /* webpackChunkName: "component---src-pages-articles-how-to-use-the-calculator-mdx" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-articles-what-is-a-pool-2-mdx": () => import("./../../../src/pages/articles/what-is-a-pool2.mdx" /* webpackChunkName: "component---src-pages-articles-what-is-a-pool-2-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

